.cx-header {
  position: relative;
  .cx-header-wrapper {
    padding: 0 30px;
    transition: all 0.5s;
    height: 60px;
    background: #fff !important;
    z-index: 222;
    position: fixed;
    top: 0;
    margin-left: 250px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    width: calc(100% - 250px) ;
display: flex;
align-items: center;
justify-content: flex-end;
    .main-heading {
      font-size: 20px;
      font-weight: 700;
      color: white;
    }

    header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      .left{
        padding: 10px 0;
        img{
          height: 48px;
          width: 48px;
          margin-right: 10px;
        }
        span{
          text-transform: uppercase;
          color: #22455e;
          font-size: 24px;
          font-weight: 600;
        }
      }
      .right{
        img{
          height: 25px;
          cursor: pointer;
        }
      }
    }
  }

  .rout-header {
    .inner-rout {
      display: flex;
    }
    display: flex;
    align-items: center;
    .slash {
      margin: 0 5px;
      font: normal normal 600 16px/23px Overpass;
      letter-spacing: 0.48px;
      color: #ffffff;
    }
    label {
      margin-right: 5px;
      display: inline-flex;
      height: 20px;
      width: 20px;
      border: 1px solid #fff;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      svg {
        transform: rotate(180deg) !important;
        height: 10px;
        margin-left: 5px;
      }
    }

    span {
      font: normal normal 600 16px/23px Overpass;
      letter-spacing: 0.48px;
      color: #ffffff;
    }
  }
}
