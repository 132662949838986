.cx-sidebar {
  .cx-sidebar-wrapper {
    transition: all 0.5s;
    width: 250px;
    background: #fff;
    position: fixed;
    top: 0;
    z-index: 55;
    height: 100vh;

    aside {
      .logo-main {
        .big {
          img {
            width: 100%;
          }
        }
        .small {
          img {
            width: 100%;
            height: 40px;
          }
        }
        align-items: center;
        display: flex;
        height: 60px;
        justify-content: start;
        padding: 0 20px;
      }
      .displayNone {
        .left {
          display: none !important;
        }
      }
      .top-logo-big {
        padding: 30px 0;

        .left {
          text-align: center;

          h6 {
            font-size: 20px !important;
            color: #cdcee2 !important;
          }
        }

        background: linear-gradient(150deg, #1e384b 0%, #44667f 100%);

        span {
          color: #fff;
          font-size: 14px;
        }
      }

      .menus-main {
        position: relative;
        transition: all 0.5s;

        height: calc(100vh - 100px);
        overflow-y: auto;

        a {
          text-decoration: none;
        }

        .menu-name {
          letter-spacing: 0.38px;
          color: #707070;
          font-size: 14px;
        }

        #arrow-double {
          border-top: 1px solid rgba(66, 72, 83, 1);
          background-color: transparent;

          #arrow-inner {
            padding: 0 20px;

            img {
              cursor: pointer;
            }
          }
        }

        .active-menu-sidebar {
          background: #747b26;
          .menu-name {
            color: #fff;
          }
        }
        .sub-menu-main {
          margin: -10px 0 10px 0;
          .active-sub-menu {
            color: #747b26 !important;
            font-weight: 600;
          }
          .sub-menu {
            padding: 10px 10px 0px 40px;
            letter-spacing: 0.38px;
            color: #707070;
            font-size: 14px;
            display: flex;
            align-items: center;
          }
          .sub-menu-item {
            margin-top: 10px;
            display: block;

          }
          .logo-sub-menues{
            height: 22px;
            margin-right: 5px;
            margin-top: -3px;
          }
        }
        .menus-items {
          padding: 15px 0;
          // border-bottom: 1px solid #84859d;

          .icon-menu-name {
            display: block;
            display: flex;
            align-items: center;
            text-decoration: none;
            margin-left: 15px;

            img {
              height: 24px;
              width: 24px;
              margin-left: 3px;
            }
            svg {
              height: 24px;
              width: 24px;
              margin-left: 3px;
            }
          }

          .icon-menu-name-arrow {
            display: flex;
            justify-content: space-between;

            .menu-left {
              display: flex;
              align-items: center;

              .icon-left {
                margin-right: 15px;

                svg {
                  height: 21px;
                  margin-left: 3px;
                  width: 21px;
                }
              }
            }
          }
        }

        .sub-menus {
          margin-bottom: 30px;
          transition: all 0.5s;
          margin-left: 11px;
          border-left: 1px solid var(--green_3DA298_LightMode);

          .sub-menue-items {
            margin-bottom: 15px;
            display: block;
            margin-left: 24px;
          }
        }
      }
    }
  }
}

.sidebarActive {
  transition: all 0.3s;

  .logo-main {
    transition: all 0.3s;
    padding: 0 10px !important;
  }
  width: 60px !important;
  // padding: 15px !important;
  transition: all 0.5s;

  label {
    color: #ffffff;
    font-size: 12px;
  }
}

.doubleArrowActive {
  transform: rotate(180deg) !important;
  text-align: center !important;
  transition: all 0.5s;
}
