@media all and (max-width: 991px) {
}
@media all and (max-width: 768px) {
  .wrapper-push-notification .left-section, .wrapper-push-notification .right-section{
    width: 100%;
  }
  .wrapper-push-notification .left-section{
    margin-bottom: 15px;
  }
  #cx-main{
    margin-left: 60px;
    padding: 75px 15px 15px 15px;
  }
  .dashboard-main .dashboard-wrapper .right-section{
    width: 100%;
  }
  .dashboard-main .dashboard-wrapper .left-section{
    margin-bottom: 15px;
    width: 100%;
  }
  .order-select-input button {
    margin-top: 15px;
  }
  .heading-common label {
    font-size: 18px;
  }
  table.dataTable.no-footer {
    margin-top: 20px;
  }
  .dataTables_wrapper .dataTables_length select {
    width: auto;
  }
  .cx-header .cx-header-wrapper header .left img {
    height: 40px;
    width: 40px;
  }
  .cx-header .cx-header-wrapper header .left span {
    font-size: 18px;
  }
  .main-login .wrapper .left .logo {
    height: 20vh;
    img {
      height: 15vh;
    }
  }
  .main-login .wrapper .right {
    height: 80vh;
  }
}
@media all and (max-width: 480px) {
  .order-select-input button {
    width: 100%;
  }
}
@media all and (max-width: 360px) {
}
