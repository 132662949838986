.dashboard-main {
  .dashboard-wrapper {
    .main-section {
      justify-content: space-between;
    }

    .left-section {
      width: calc(50% - 5px);

      h5 {
        color: #44667f;
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: 600;
        border-bottom: 1px solid #898e8e;
        padding: 0 15px 15px 15px;
      }

      background-color: #ffffff;
      border-radius: 10px;
      padding: 15px;
    }

    .right-section {
      .ineerCard {
        .innerText {
          background-color: #747b26;

          .headingText,
          .count {
            color: #ffffff !important;
          }
        }

        .innerIcon {
          background-color: #ffffff !important;
        }
      }

      // width: calc(50% - 5px);

      background-color: #ffffff;
      border-radius: 10px;
      padding: 15px;

      h5 {
        color: #44667f;
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: 600;
        border-bottom: 1px solid #898e8e;
        padding: 0 15px 15px 15px;
      }
    }

    .mainCards {
      text-decoration: none;

      .ineerCard {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        height: 100px;

        width: 100%;

        display: flex;

        cursor: pointer;

        background-color: #747b26;

        position: relative;

        overflow: hidden;

        margin-bottom: 20px;

        border: 0px solid transparent;

        border-radius: 0.75rem;

        .innerIcon {
          background-color: #747b26;

          width: 80px;

          // padding: 10px;

          text-align: center;

          line-height: 80px;

          img {
            // color: #fff;

            text-align: center;

            vertical-align: middle;

            margin-top: 10px;

            height: 50px;

            width: 50px;

            line-height: 80px;
          }
        }
      }

      .innerText {
        width: 70%;

        padding: 12px;

        display: flex;

        flex-direction: column;

        .headingText {
          color: #44667f;

          font-size: 16px;

          font-weight: 550;

          text-decoration: none;
        }

        .count {
          margin-top: 0px;
          font-size: 22px;

          font-weight: 550;

          color: #44667f;

          text-align: end;
        }
      }
    }
  }
}

.heading-btn-main {
  display: flex;

  justify-content: space-between;

  align-items: center;

  margin-bottom: 20px;
}

.heading-common {
  label {
    font-size: 24px;

    font-weight: 700;

    color: #84859d;
  }
}

.buttons-modal {

  button,
  a {
    text-decoration: none;

    border: none;

    background-color: #44667f;

    color: #fff;

    border-radius: 10px;

    padding: 5px 15px;
  }
}

.admin-main {
  .add-btn {
    text-align: end;

    button {
      border: none;

      color: #fff;

      border-radius: 5px;

      padding: 7px 10px;

      background-color: #84859d;

      &:hover {
        background-color: #696a7d;
      }

      svg {
        height: 20px;

        margin-bottom: 2px;
      }
    }
  }
}

.table-wrapper {
  overflow-x: auto;

  background-color: #fff;

  padding: 20px;

  border-radius: 10px;

  table {
    th {
      //   text-align: center;
    }

    td {
      text-align: center;
    }

    min-width: 900px;

    thead {
      tr:first-child th:last-child {
        border-top-right-radius: 10px;
      }

      tr:first-child th:last-child {
        border-bottom-right-radius: 10px;
      }

      tr:first-child th:first-child {
        border-top-left-radius: 10px;
      }

      tr:first-child th:first-child {
        border-bottom-left-radius: 10px;
      }

      background-color: #44667f;

      th {
        color: #fff;
      }
    }

    .verify {
      a {
        display: inline-block;
        width: 130px;
        padding: 3px;
        color: #fff;
        text-decoration: none;
        border-radius: 5px;

        background-color: #84859d;

        &:hover {
          background-color: #696a7d;
        }
      }
    }

    .action {
      .icon {
        img {
          height: 18px;

          width: 18px;

          vertical-align: middle;
        }

        margin: 0 3px;
        padding: 2px 5px;

        border-radius: 5px;

        background-color: #84859d;
      }
    }
  }
}

table.dataTable thead th {
  text-align: center;
}

.dataTables_wrapper .dataTables_length {
  margin-bottom: 20px;
}

.dataTables_wrapper .dataTables_length select {
  margin-left: 10px;

  width: 150px;

  outline: none;
}

.dataTables_wrapper .dataTables_filter input {
  outline-offset: 0px;

  outline: none;
}

.dataTables_empty {
  color: red;
}

.dataTables_wrapper .dataTables_paginate {
  margin-top: 20px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background-color: #44667f;

  color: #fff !important;

  &:hover {
    background-color: #44667f;

    color: #fff !important;
  }
}

.dataTables_wrapper .dataTables_info {
  margin-top: 20px;
}

.heading-btn-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.heading-common {
  label {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
    color: #000;
  }
}

.buttons-modal {

  button,
  a {
    text-decoration: none;
    border: none;
    background-color: #747b26;
    color: #fff;
    border-radius: 5px;
    padding: 5px 15px;
  }
}

.admin-main {
  .customBTN{
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 15px;
  }
  .add-btn {
    text-align: end;
   
    button {
      border: none;
      color: #fff;
      border-radius: 5px;
      padding: 7px 10px;
      background-color: #747b26;

      &:hover {
        background-color: #afb57d;
      }

      svg {
        height: 20px;
        margin-bottom: 2px;
      }
    }
  }
}

.table-wrapper {
  overflow-x: auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;

  table {
    th {
      //   text-align: center;
    }

    td {
      text-align: center;
    }

    min-width: 800px;

    thead {
      tr:first-child th:last-child {
        border-top-right-radius: 10px;
      }

      tr:first-child th:last-child {
        border-bottom-right-radius: 10px;
      }

      tr:first-child th:first-child {
        border-top-left-radius: 10px;
      }

      tr:first-child th:first-child {
        border-bottom-left-radius: 10px;
      }

      background-color: #afb57d;

      th {
        color: #fff;
      }
    }

    .action {
      .view {
        background-color: #6c757d;

        &:hover {
          background-color: #94a2af;
          transition: all 0.2s;
        }
      }

      .edit {
        background-color: #198754;

        &:hover {
          background-color: rgb(94, 184, 94);
          transition: all 0.2s;
        }
      }

      .delete {
        background-color: #dc3545;

        &:hover {
          background-color: rgb(192, 64, 64);
          transition: all 0.2s;
        }
      }

      .icon {
        img {
          height: 18px;
          width: 18px;
          vertical-align: middle;
        }

        margin: 0 3px;
        padding: 2px 5px;
        border-radius: 5px;
      }
    }
  }
}

table.dataTable thead th {
  text-align: center;
}

.dataTables_wrapper .dataTables_length {
  margin-bottom: 20px;
}

.dataTables_wrapper .dataTables_length select {
  margin-left: 10px;
  width: 150px;
  outline: none;
}

.dataTables_wrapper .dataTables_filter input {
  outline-offset: 0px;
  outline: none;
}

.dataTables_empty {
  color: red;
}

.dataTables_wrapper .dataTables_paginate {
  margin-top: 20px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background-color: #afb57d;
  color: #fff !important;

  &:hover {
    background-color: #afb57d;
    color: #fff !important;
  }
}

.dataTables_wrapper .dataTables_info {
  margin-top: 20px;
}

.modal-content {
  .add-newSabaq-btn {
    text-decoration: none;
    width: calc(100% - 1.8rem);
    margin: 0 0.9rem 15px 0.9rem;
  }

  .sub-group-main {
    margin-bottom: 15px !important;
    margin: 0 0.9rem;
    padding: 10px;
    width: calc(100% - 1.8rem);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .p-details-img {
    display: flex;
    flex-wrap: wrap;

    img {
      height: 90px;
      width: 90px;
      border-radius: 10px;
      margin-right: 15px;
    }
  }

  .product-img-main {
    flex-wrap: wrap;
    margin: 40px 0 30px 0;

    .products {
      margin-right: 20px;
      margin-bottom: 20px;
      width: 90px;
      height: 90px;
      padding: 10px 0;
      text-align: center;
      position: relative;
      border: 1px dashed gray;
      border-radius: 10px;

      .cross {
        position: absolute;
        right: -8px;
        top: -8px;
        height: 15px;
        width: 15px;
      }

      span {
        font-size: 11px;
        color: gray;
        display: block;
      }

      svg {
        display: inline-block;
        height: 50px;
        width: 50px;
      }

      .img-product {
        height: 70px;
        width: 70px;
      }
    }
  }

  .details-input {
    width: calc(100% - 70px);

    .remove {
      img {
        height: 20px;
        width: 20px;
      }

      cursor: pointer;
      position: absolute;
      right: -55px;
      bottom: 7px;
    }
  }

  .modal-title {
    font-size: 18px;
    font-weight: 600;
  }

  .check-input {
    cursor: pointer;
    margin-left: 30px;
    margin-top: 8px;
    display: flex;
    align-items: center;

    .form-check-input {
      height: 30px !important;
      width: 30px;
      margin-right: 10px;
    }
  }

  .user-detail {
    margin-bottom: 15px;

    label {
      color: #84859d;
      font-size: 14px;
    }

    span,
    p {
      font-size: 14px;
      font-weight: 600;
      color: #000000;
      // margin-left: 30px;
    }
  }

  .table-user-details {
    overflow-x: auto;

    table {
      border: 1px solid #44667f;
      border-collapse: collapse;
      min-width: 1000px;
      width: 100%;

      th,
      td {
        padding: 7px 10px;
        border: 1px solid #44667f;
        text-align: center;
        vertical-align: middle;
        font-size: 14px;
      }

      thead {
        .item-heading {
          th {
            color: #84859d;
          }
        }

        .table-heading {
          th {
            color: #44667f;
          }
        }
      }

      tbody {
        td {
          color: #222222;
        }
      }
    }
  }
}

.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none;
}

.order-select-input {
  align-items: flex-end;

  button {
    text-decoration: none;
    border: none;
    background-color: #44667f;
    color: #fff;
    border-radius: 5px;
    padding: 5px 15px;
  }
}

.modal-content {
  .key {
    color: #707070;
    font-size: 16px;
  }

  .value {
    font-size: 14px;

    color: #000000;
    font-weight: 600;
  }
}

.wrapper-push-notification {
  .main-section {
    justify-content: space-between;

    h4 {
      font-size: 20px;
      color: #747b26;
      font-weight: 600;
      margin-bottom: 20px;
    }

    h5 {
      font-size: 16px;
      color: #747b26;
      font-weight: 600;
    }
  }

  .left-section {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: calc(50% - 5px);
    background-color: #ffffff;
    border-radius: 10px;
    padding: 15px;

    p {
      font-size: 14px;
    }

    .step {
      padding: 10px;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      background-color: cream;
    }

    .v-stepper {
      position: relative;
      /*   visibility: visible; */
    }

    /* regular step */
    .step .circle {
      background-color: white;
      border: 10px solid gray;
      border-radius: 100%;
      width: 32px;
      height: 32px;
      display: inline-block;
    }

    .step .line {
      top: 23px;
      left: 14px;
      /*   height: 120px; */
      height: 100%;

      position: absolute;
      border-left: 3px solid #747b26;
    }

    .step.completed .circle {
      visibility: visible;
      background-color: rgb(6, 150, 215);
      border-color: rgb(6, 150, 215);
    }

    .step.completed .line {
      border-left: 3px solid #747b26;
    }

    .step.active .circle {
      visibility: visible;
      border-color: #747b26;
    }

    .step.empty .circle {
      visibility: hidden;
    }

    .step.empty .line {
      /*     visibility: hidden; */
      /*   height: 150%; */
      top: 0;
      height: 150%;
    }

    .step:last-child .line {
      border-left: 3px solid white;
      z-index: -1;
      /* behind the circle to completely hide */
    }

    .content {
      margin-left: 20px;
      display: inline-block;
    }
  }

  .right-section {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: calc(50% - 5px);
    background-color: #ffffff;
    border-radius: 10px;
    padding: 15px;

    .form-control {
      border-radius: 0;
    }

    .form-label {}

    button {
      border-radius: 7px;
      padding: 6px 25px;
      border: none;
      background-color: #747b26;
      color: #ffffff;
    }
  }
}

.nav-link.active {
  background-color: #747b26 !important;
}

.nav-link {
  color: #747b26;
  font-weight: 600;

  &:hover {
    color: #747b26;
    font-weight: 600;
  }
}

.modal-content .feature-wrapper {
  border-radius: 7px;

  .buttons-main {
    text-align: end;

    button {
      border: none;
      padding: 7px 15px;
      border-radius: 7px;
      color: #fff;
    }

    .submit-btn {
      background-color: #747b26;
      margin-left: 10px;
    }

    .cancel-btn {
      background-color: #44667f;
    }
  }
}

#maktab-main {
  .exam-paper-main {}

  .feature-lists {
    // justify-content: space-evenly;
    position: relative;

    .add-btn {
      button {
        // margin-top: 20px;
      }
    }

    .feature-card {
      margin: 15px 0;
      background-color: #fff;
      border-radius: 7px;
      padding: 15px;

      // width: calc(33.33333333% - 15px);
      .date-ref-dialy {
        display: flex;
        justify-content: space-between;
         overflow-wrap: anywhere;

        span,
        p {
          font-size: 10px;
          color: #696a7d;
         
          // width: 100%;
        }
      }

      .img {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .f-icon {
          height: 70px;
          width: 70px;
        }

        .add-btn {
          button {
            img {
              height: 15px;
              width: 15px;
            }

            padding: 2px 7px;
            margin-top: 0;
          }
        }
      }

      .heading {
        label {
          margin: 10px 0;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .content {
        word-break: break-all;
        text-align: justify;
        font-size: 14px;
        color: gray;
      }
    }
  }

  .add-btn {
    .delete {
      background-color: red;
      margin-right: 7px;

      &:hover {
        background-color: rgb(196, 69, 69);
      }
    }

    .view {
      background-color: #747b26;
      margin-right: 7px;
      height: 25px;
      opacity: 1;

      img {}

      &:hover {
        background-color: #afb57d;
      }
    }
  }

  .workshopSteps-main {
    .wrapper {
      // justify-content: space-evenly;
      img{
        width: 100%;
      }
      .videoStepsCard {
        margin: 15px 0;
        background-color: #fff;
        border-radius: 7px;
        padding: 15px;
        // width: calc(33.33333333% - 15px);

        .add-btn {
          .delete {
            background-color: red;
            margin-right: 7px;

            &:hover {
              background-color: rgb(196, 69, 69);
            }
          }

          .edit {}

          button {
            padding: 2px 7px;

            img {
              height: 15px;
            }
          }
        }

        .key {
          font-size: 14px;
          font-weight: 600;
        }

        .value {
          font-size: 14px;
          color: gray;
        }

        .desc {
          p {
            text-align: justify;
            word-wrap: break-word;
          }
        }

        .video {
          video {
            margin: 10px 0;
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  .video-wrapper {
    background-color: #fff;
    padding: 15px;
    border-radius: 7px;

    .video {
      .left .upload {
        height: 100%;
        border-radius: 10px;
        border: 1px dotted red;
      }

      .right {
        video {
          height: 300px;
          width: 100%;
        }
      }
    }
  }

  .exam-paper-main {
    .exam-paper {
      // justify-content: space-evenly;
      padding: 15px;
      margin-top: 20px;
      background-color: #fff;
      border-radius: 7px;

      h5 {
        font-weight: 700;
      }

      .paper-section {
        // width: calc(33.33333333% - 15px);
        padding: 15px;
        margin: 15px 0;
        background-color: #fff;
        border-radius: 7px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        h6 {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 15px;
        }

        label,
        a {
          text-decoration: none;
          margin-bottom: 7px;
          display: block;
          color: #707070;
          font-size: 14px;
        }

        .paper_year_main {
          margin-bottom: 10px;

          a {
            margin-bottom: 0;
          }

          .add-btn {
            button {
              padding: 0 5px;

              img {
                height: 15px;
              }
            }

            .delete {}
          }

          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }

  .jobDescriptio-main {
    .jd-main {
      // justify-content: space-evenly;
      // flex-wrap: wrap;
      position: relative;
    }

    .jd-cards {
      padding: 15px;
      margin-top: 20px;
      background-color: #fff;
      border-radius: 7px;

      // width: calc(33.33333333% - 15px);
      .add-btn {
        button {
          img {
            height: 15px;
            width: 15px;
          }

          padding: 2px 7px;
          margin-top: 0;
        }
      }

      h6 {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        word-break: break-all;
        margin-top: 10px;
      }

      p {
        white-space: break-spaces;
        text-align: justify;
        font-size: 14px;
        color: #707070;
      }
    }

    margin-top: 20px;

    .nav-link.active {
      background-color: transparent !important;
      color: #b33a40;
      font-weight: 700;
      border-bottom: 2px solid #b33a40;
    }

    .nav-link {
      margin-right: 15px;
      color: #000;
      border-radius: 0;
    }
  }

  .outer-main-video {
    margin-bottom: 20px;

    .content {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .key {
      font-size: 14px;
      font-weight: 600;
    }

    .value {
      font-size: 14px;
      color: gray;
    }

    .video {
      width: 100%;
      // position: relative;

      a {
        display: block;
        width: 100%;
        position: relative;

        img {
          // width: 325px;
          width: 100%;
          height: 183px;
          z-index: 1;
        }

        .play-btn {
          position: absolute;
          // z-index: 9999;
          z-index: 1;
          top: 50%;
          left: 50%;
          width: 45px;
          height: 35px;
          border-radius: 5px;
          transform: translate(-68%, -40%);

          .button {
            position: relative;
            font-family: inherit;
            transition: all 0.1s ease-in;
            display: flex;
            gap: 7px;
            cursor: pointer;
            border-radius: 5px;
            padding: 0.2rem 1rem;
            background-color: #747b26;
            color: #fff !important;
            border: none;
            align-items: center;
            font-size: 14px;
            // font-weight: 600;
            min-width: 3rem;
            justify-content: center;
          }

          .button:hover {
            background-color: #2e77f2;
          }

          .arrow {
            position: relative;
            transition: all 0.4s ease-in-out;
            cursor: pointer;
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 8px solid #fff;
          }

          .arrow::after {
            transition: transform 0.4s ease-in-out;
            content: "";
            position: absolute;
            background-color: transparent;
            width: 1.5rem;
            height: 2rem;
            top: -1rem;
          }

          .arrow::before {
            transition: all 0.3s ease-out;
            content: "";
            position: absolute;
            background-color: transparent;
            width: 1rem;
            height: 1.6rem;
            top: -0.8rem;
          }
        }
      }

      // video {
      //   width: 100%;
      //   height: auto;
      // }
    }
  }

  .app-link-style {
    margin-right: 5px;
    padding: 0.5rem 1rem;
    text-decoration: none;
    color: #747b26;
    font-weight: 600;
    border-radius: 0.375rem;

    &:hover {
      color: #fff;
      background-color: #747b26;
      transition: all 0.3s;
    }
  }
}

.dashboard-video {
  .add-btn button {
    background-color: #747b26;
    border: none;
    border-radius: 5px;
    color: #fff;
    padding: 7px 10px;
  }
}

#month-tab {
  .month-list {
    .month-list-card-main {
      // justify-content: space-between;
    }

    .month-list-card {

      // width: calc(33.33333333% - 10px);
      p {
        text-align: justify;
      }

      .add-btn {
        margin-bottom: 15px;

        button {
          padding: 2px 5px;

          img {
            height: 15px;
            width: 15px;
          }
        }
      }

      padding: 15px;
      border-radius: 10px;
      background-color: #fff;
      margin-top: 15px;
    }
  }

  margin-top: 15px;

  .nav-link.active {
    border: 1px solid #673ab7 !important;

    color: #673ab7 !important;
  }
}

// .daily-post-main{
//   .feature-lists{
//     justify-content: space-between;
// .feature-card{

// }
//   }
// }

.publication-tab-content {
  .add-btn {
    margin-bottom: 20px;
  }

  .books-card-main {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;

    .btns-top {
      text-align: end;

      .delete {
        margin: 0 5px;
        background-color: red;
        border: none;
        border-radius: 5px;
        padding: 1px 5px;

        img {
          height: 15px;
          width: 15px;
        }
      }

      .edit {
        background-color: #747b26;
        border: none;
        border-radius: 5px;
        padding: 1px 5px;

        img {
          height: 15px;
          width: 15px;
        }
      }

      .view {
        background-color: #44667f;
        border: none;
        border-radius: 5px;
        padding: 1px 5px;

        img {
          height: 15px;
          width: 15px;
        }
      }
    }

    .inner-wrapper {
      .book-img {
        margin: 10px 0;
        display: inline-block;

        img {
          width: 100%;
          height: auto;
        }
      }

      .name {
        font-size: 14px;
        overflow-wrap: anywhere;
        width: 100%;
      }

      .line {
        font-size: 12px;
        color: #696a7d;
        overflow-wrap: anywhere;
        width: 100%;
      }
    }
  }
}

.quraan-details {
  text-align: center;

  .content {
    .img {
      margin-bottom: 15px;

      img {
        max-width: 300px;
      }
    }

    .heading {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .details {
      font-size: 16px;
      padding: 0 100px;
    }

    .app-btn {
      text-decoration: none;
      color: #fff;
      background-color: grey;
      padding: 5px 10px;
      border-radius: 5px;
    }
  }
}

.back-btns {
  margin-right: 10px;
  background-color: #44667f;
  padding: 3px 15px;
  border-radius: 5px;
}

.course-details {
  .course-heading {
    font-weight: bold;
    font-size: 18px;
  }

  .course-des {
    font-size: 16px;
  }
}

.assessment-wrapper {
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;

  .select-wrapper {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid gray;
  }
}

.assessment-card {
  margin-top: 15px;

  .q-key {
    font-weight: 600;
    font-size: 16px;
    word-wrap: break-word;
  }

  .q-value {}

  .options {
    li {
      margin-bottom: 10px;

      label {
        margin-left: 5px;
        overflow-wrap: anywhere;
        width: 100%;
      }
    }

    .correct-ans {
      color: #747b26;
      font-weight: 600;

      label {
        // background-color: #747b26;

        border-radius: 5px;
      }
    }
  }
}

#assessment-main {
  .btns-top {
    display: flex;
    justify-content: space-between;
  }

  .q-number {
    h4 {
      font-weight: 600;
      font-size: 16px;

      span {
        margin-left: 5px;
      }
    }
  }
}